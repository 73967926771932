import {
  createListenerMiddleware,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'

import { AppState } from '@store/store'
import { appActions } from '@store/slices/app.slice'
import { playerActions } from '@store/slices/player.slice'
import { moviesActions } from '@store/slices/movies/movies.slice'

import {
  RECENTLY_VIEWED_EPISODES,
} from '@utils/vars'
import { getHlsPlayerElement } from '@utils/player/getHlsPlayerElement'

import {
  IAddChannelsToExtraCategoryPayload,
  ILastWatchedCatchupProgramData,
} from '@assets/types/player.types'

export const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: isAnyOf(appActions.app_setLastWatchedCatchupProgram),
  effect: (_, listenerApi) => {
    const appState = listenerApi.getState() as AppState
    const currentProgramIdx =
      appState.player.hlsPlayer.currentChannelEpg.currentProgramIdx
    const items = appState.player.hlsPlayer.currentChannelEpg.items
    const selectedCategoryIdx = appState.player.categories.selectedCategoryIdx
    const categories = appState.player.categories.items
    const selectedChannelIdx = appState.player.channels.selectedChannelIdx
    const timeshiftValue = appState.player.hlsPlayer.timeshift.timeshiftValue
    const { logo, id, names, type } =
      categories[selectedCategoryIdx].channels[selectedChannelIdx]
    const { start_at, stop_at, title, m3u8, id: progId } = items[currentProgramIdx]

    const lastWatchedCatchupProgramData: ILastWatchedCatchupProgramData = {
      id: progId,
      channelLogo: logo,
      channelId: id,
      channelNames: names,
      currentTime: timeshiftValue,
      startAt: start_at,
      stopAt: stop_at,
      programName: title,
      type,
      m3u8,
    }
    listenerApi.dispatch(appActions.app_setLastWatchedCatchupProgramData(lastWatchedCatchupProgramData))
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(
    moviesActions.movies_loadRecentlyViewedEpisodesFromLocalStorage
  ),
  effect: (_, listenerApi) => {
    const appState = listenerApi.getState() as AppState
    const recentlyViewedEpisodes = appState.movies.recentlyViewedEpisodes
    if (recentlyViewedEpisodes?.length) {
      localStorage.setItem(
        RECENTLY_VIEWED_EPISODES,
        JSON.stringify(recentlyViewedEpisodes)
      )
    } else {
      localStorage.removeItem(RECENTLY_VIEWED_EPISODES)
    }
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(
    playerActions.player_categories_setChannelItemsToExtraChannelsCategory
  ),
  effect: (
    action: PayloadAction<IAddChannelsToExtraCategoryPayload>,
    listenerApi
  ) => {
    const appState = listenerApi.getState() as AppState
    const category = appState.player.categories.items.find(
      (category) => category.id === action.payload.targetCategoryId
    )

    if (!category?.channels.length)
      return localStorage.removeItem(action.payload.localStorageItemName)

    const channelsIds: string[] = []

    category?.channels.forEach((channel) =>
      channelsIds.push(channel.id.toString())
    )

    localStorage.setItem(
      action.payload.localStorageItemName,
      JSON.stringify(channelsIds)
    )
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(playerActions.player_hlsPlayer_rewindToTimestamp),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effect: (action: PayloadAction<number>, _) => {
    const video = getHlsPlayerElement()
    if (video) video.currentTime = action.payload
  },
})
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { listenerMiddleware } from '@store/middleware'

import { authApi, authReducer } from '@store/slices/auth.slice'
import { appApi, appReducer } from '@store/slices/app.slice'
import {
  moviesAsideApi,
  moviesAsideReducer,
} from '@store/slices/movies/aside.slice'

import {
  moviesFilmDetailsApi,
  moviesFilmDetailsReducer,
} from '@store/slices/movies/filmDetails.slice'
import { moviesReducer } from '@store/slices/movies/movies.slice'

import {
  moviesFilmListApi,
  moviesFilmListReducer,
} from '@store/slices/movies/filmlist.slice'
import { recentlyViewedReducer } from '@store/slices/movies/recentlyViewed'

import {
  appLeftMenuApi,
  appLeftMenuReducer,
} from '@store/slices/app.left.menu.slice'

import { playerApi, playerReducer } from '@store/slices/player.slice'

import {
  appRightMenuApi,
  appRightMenuReducer,
} from '@store/slices/app.right.menu.slice'

import { overlaysApi, overlaysReducer } from '@store/slices/overlays.slice'

import { dialogsReducer } from '@store/slices/dialogs.slice'

import {LAST_WATCHED_CATCHUP_PROGRAM_DATA} from '@utils/vars'

const appLastWatchedCatchupProgramDataPersistConfig = {
  key: 'lastWatchedCatchupProgramData',
  whitelist: [LAST_WATCHED_CATCHUP_PROGRAM_DATA],
  storage,
}

const combinedReducer = combineReducers({
  auth: authReducer,
  player: playerReducer,
  app: persistReducer(appLastWatchedCatchupProgramDataPersistConfig, appReducer),
  overlays: overlaysReducer,
  dialogs: dialogsReducer,
  appLeftMenu: appLeftMenuReducer,
  appRightMenu: appRightMenuReducer,
  movies: moviesReducer,
  moviesAside: moviesAsideReducer,
  moviesFilmList: moviesFilmListReducer,
  moviesFilmDetails: moviesFilmDetailsReducer,
  recentlyViewed: recentlyViewedReducer,
  [authApi.reducerPath]: authApi.reducer,
  [playerApi.reducerPath]: playerApi.reducer,
  [appApi.reducerPath]: appApi.reducer,
  [overlaysApi.reducerPath]: overlaysApi.reducer,
  [appLeftMenuApi.reducerPath]: appLeftMenuApi.reducer,
  [appRightMenuApi.reducerPath]: appRightMenuApi.reducer,
  [moviesAsideApi.reducerPath]: moviesAsideApi.reducer,
  [moviesFilmListApi.reducerPath]: moviesFilmListApi.reducer,
  [moviesFilmDetailsApi.reducerPath]: moviesFilmDetailsApi.reducer,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  if (action.type === 'app/app_logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        authApi.middleware,
        playerApi.middleware,
        appApi.middleware,
        overlaysApi.middleware,
        appLeftMenuApi.middleware,
        appRightMenuApi.middleware,
        moviesAsideApi.middleware,
        moviesFilmListApi.middleware,
        moviesFilmDetailsApi.middleware
      ),
      listenerMiddleware.middleware,
    ],
    devTools: __MODE__ === 'development',
  })
}

export const store = makeStore()
export const persistor = persistStore(store)
export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>

export const ALL_CHANNELS_CATEGORY_ID = 0
export const FAVOURITE_CHANNELS_CATEGORY_ID = -3
export const BLOCKED_CHANNELS_CATEGORY_ID = -4

// local storage
export const LAST_WATCHED_CATEGORY_ID = 'lastWatchedCategoryId'
export const LAST_WATCHED_CHANNEL_ID = 'lastWatchedChannelId'
export const USER_TOKEN = 'userToken'
export const SESSION_TOKEN = 'sessionToken'
export const USER_PHONE = 'userPhone'
export const PARENTAL_CONTROL = 'parentalContol'
export const OMEGATV_MODEL_NAME = 'OmegaTVModelName'
export const OMEGATV_MAC_ADDRESS = 'OmegaTVMacAddress'
export const OMEGATV_SERIAL_NUMBER = 'OmegaTVSerialNumber'
export const FAVOURITE = 'favourite'
export const BLOCKED = 'blocked'
export const CHANNEL_NAME_IN_THE_LIST = 'channelNameInTheList'
export const LANGUAGE = 'language'
export const RECENTLY_VIEWED_EPISODES = 'recentlyViewedEpisodes'
export const RECENTLY_VIEWED_FILMS = 'recentlyViewedFilms'

//persist
export const LAST_WATCHED_CATCHUP_PROGRAM_DATA = 'lastWatchedCatchupProgramData'

// auto hide elements
export const DEFAULT_AUTO_HIDE_ELEMENTS = 10000
export const DEFAULT_AUTO_HIDE_FIND_CHANNELS_BY_KEY = 3000

// marquee animation
export const DELTA_SPEED = 25

// shared fixed cache keys
export const SHARED_FIND_CHANNEL_BY_KEY = 'shared-find-channel-by-key'
export const SHARED_ACTIVATE_VOUCHER = 'shared-activate-voucher'
export const SHARED_B2B_ACTIVATE_BY_CODE = 'shared-b2b-activate-by-code'
export const SHARED_GET_EPG_RANGE_PLAY_EPISODE =
  'shared-get-epg-range-play-episode'
export const SHARED_GET_EPG_RANGE_PLAY_FILM = 'shared-get-epg-range-play-film'
export const SHARED_EDIT_DEVICE = 'edit-device'
export const SHARED_GET_EPG_RANGE_PLAY_PREV_EPISODE =
  'shared-get-epg-range-prev-episode'
export const SHARED_GET_EPG_RANGE_PLAY_NEXT_EPISODE =
  'shared-get-epg-range-next-episode'
export const SHARED_LEFT_MENU_GET_TIMESHIFT_URL = 'left-menu-get-timeshift-url'

// customizations (companies id's)
export const ITTV_ID = '512'
export const BILINK_ID = '626'
export const MAXIMUM_ID = '133'

// plans
export const PROMO_PLAN_ID = '14'

// id
export const HLS_PLAYER_ID = 'hls-player-id'

// storage time 
export const DEFAULT_STORAGE_TIME = 604800
export const FAST_DEFAULT_STORAGE_TIME = 259200
